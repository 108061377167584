.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

a {
  color: inherit;
}

@media only screen and (orientation: landscape) {
  p {
    max-width: 25vw !important;
  }
}

.fa {
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  font-size: 20px;
  text-align: center;
  height: 20px;
  width: 20px;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.7;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-envelope {
  background: red;
  color: white;
}

.fa-github {
  background: #000000;
  color: white;
}

.fa-whatsapp {
  background: green;
  color: white;
}

.aboutme-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.profile-pic > ul,
.profile-pic > li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.profile-pic > ul {
  display: flex;
  text-align: center;
  justify-content: space-between;
  border-radius: 50%;
  border: #000000 2px solid;
}

.profile-pic > ul li {
  text-align: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.profile-pic > ul li img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: fill;
  background-color: white;
}

.blockquote-wrapper {
  display: flex;
  padding: 0 20px;
}

/* Blockquote main style */
.blockquote {
  position: relative;
  font-family: "Square Peg", cursive;
  max-width: 620px;
  margin: 80px auto;
  align-self: center;
  max-width: 50vw !important;
}

/* Blockquote header */
.blockquote h2 {
  font-family: "Square Peg", cursive;
  position: relative; /* for pseudos */
  color: #e74848;
  margin: 0;
  border: 2px solid #fff;
  border: solid 2px;
  border-radius: 20px;
  padding: 25px;
}

/* Blockquote right double quotes */
.blockquote h2:after {
  content: "";
  position: absolute;
  border: 2px solid #e74848;
  border-radius: 0 50px 0 0;
  width: 20px;
  height: 20px;
  bottom: -22px;
  left: 50px;
  border-bottom: none;
  border-left: none;
  z-index: 3;
}

.blockquote h2:before {
  content: "";
  position: absolute;
  width: 10px;
  border: 6px solid #ffffff;
  bottom: -3px;
  left: 50px;
  z-index: 2;
}

/* increase header size after 600px */
@media all and (min-width: 600px) {
  .blockquote h1 {
    font-size: 3rem;
    line-height: 1.2;
  }
}

/* Blockquote subheader */
.blockquote h4 {
  position: relative;
  color: #000000;
  margin: 0;
  padding-top: 15px;
  z-index: 1;
  margin-left: 80px;
  padding-left: 12px;
}

.blockquote h4:first-letter {
  margin-left: -12px;
}

li {
  list-style: none;
}
