.footimage {
    display: flex;
    max-width: 50vw;
    height: auto;
    }

    .footer-section{
        display: flex;
        justify-content:space-between;
        margin: 0 0 0 20px;
        bottom: 0;
    }
        
    .copyright-section{
        font-size: 0.6em;
        font-family: 'Montserrat', sans-serif;
        align-self: center;
        padding-bottom: 5px;
    }